import React from "react"
import { Link } from "gatsby"

import Layout from "../components/new_layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div id="main-wrapper">
      <div id="main-content">
        <div class="boxed">
          <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/priceleeks-beta/pl-logo.png" class="pl-logo" />
          <h4>Does PriceLeeks app work worldwide</h4>
          <p>You can use the PriceLeeks App anywhere in the world, however, Our lists of Vendors and Service providers are US based only at the moment.</p>

          <br/>
          <h4>Are the listing shown to me updated</h4>
          <p>We are keeping our records updated in the best of our ability. If you find some information that are outdated, please let us know at <Link to="mailto:support@priceleeks.com">support@priceleeks.com</Link> and we will contact the Vendors or Service providers to give us their updated informations.</p>

          <br/>
          <h4>How should we contact you for inquiries regarding the App</h4>
          <p>If you are having any problems using the PriceLeeks App or you have some inquiries regarding the PriceLeeks App, you may reach us at <Link to="mailto:support@priceleeks.com">support@priceleeks.com</Link></p>

          <div class="support-links">
            <Link to="mailto:support@priceleeks.com">Email Support</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>

        </div>

      </div>

      <div id="main-sep"></div>

      <div id="main-screenshot">
        <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/priceleeks-beta/priceleeks-screenshot-new.png" class="home-screenshot" />
      </div>

    </div>
    




  </Layout>
)

export default IndexPage
